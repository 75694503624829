import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import PageHeader from "../../components/pageHeader"
import SEO from "../../components/seo"

import ScrollAnimation from 'react-animate-on-scroll';

import ImageDevelopement2 from '../../components/images/imageDevelopement2.js'
import ImageTransition from '../../components/images/imageTransition.js'
import ImageEmploi from '../../components/images/imageEmploi.js'
import ImageLeft2 from '../../components/images/imageLeft2.js'

const Integration = () => (
  <Layout>
    <SEO title="Intégration à de nouvelles fonctions de gestion | Coaching | Gestion Mieux-Être" />
    <PageHeader
        header="Coaching d'intégration à vos nouvelles fonctions"
        text="Le coaching est l'outil tout indiqué lors d’une transition professionnelle importante, qu’il s’agisse de l’intégration dans un nouvel emploi ou d’un changement de niveau de gestion. "
        quote="La logique vous conduira d’un point A à un point B.  L’imagination et l’audace vous conduiront où vous le désirez<span>- Einstein</span>"
        background="coaching" />
    <div className="main-wrapper pad-page tcenter">
        <ScrollAnimation
            delay="0"
            animateIn="fadeIn">
        <p className="service-presentation2">Vous voilà gestionnaire, félicitations!  Maintenant, assurez-vous que votre transition de carrière soit empreinte de succès.   Les défis sont multiples!</p>
        <p className="service-presentation2">Misez sur vos compétences et vos talents pour relever avec brio vos nouveaux défis! Nous vous accompagnons afin de: </p>
        <div className="flex-container pad2">
            <div className="half"><ImageLeft2 /></div>
            <div className="half">
                <ul className="success">
                    <li>Concevoir avec pertinence le plan d'intégration de vos 100 premiers jours</li>
                    <li>Décoder les paramètres de votre nouvelle culture organisationnelle</li>
                    <li>Accélérer votre positionnement au sein de vos nouvelles fonctions</li>
                    <li>Établir des alliances avec les joueurs-clés (patron, collègues, partenaires, clients et employés)</li>
                </ul>
            </div>
        </div>
        <p className="service-presentation2">Nous vous proposons de poser un regard avisé sur vos nouvelles fonctions en multipliant les perspectives. Ce type d'accompagnement consolide de façon déterminante tous les aspects de votre intégration afin d’accéder rapidement au succès.</p>
        <Link className="button-link" to="/nous-joindre">Nous joindre</Link>
        </ScrollAnimation>
        <div className="upperline"></div>
        <br /><br />
        <h1>Services connexes</h1>
        <br /><br /><br /><br />
        <div className="flex-container2">
            <ScrollAnimation
                delay="20"
                animateIn="fadeIn"
                className="third">
            <div>
                <Link to="coaching/developpement-des-competences">
                    <ImageDevelopement2 />
                    <h6>Développement de compétences relationnelles ou de gestion</h6>
                </Link>
            </div>
            </ScrollAnimation>
            <ScrollAnimation
                delay="30"
                animateIn="fadeIn"
                className="third">
            <div>
                <Link to="coaching/transition-de-carriere">
                    <ImageTransition />
                    <h6>Transition de carrière</h6>
                </Link>
            </div>
            </ScrollAnimation>
            <ScrollAnimation
                delay="40"
                animateIn="fadeIn"
                className="third">
            <div>
                <Link to="coaching/recherche-emploi">
                    <ImageEmploi />
                    <h6>Recherche d’emploi</h6>
                </Link>
            </div>
            </ScrollAnimation>
        </div>
    </div>
  </Layout>
)

export default Integration
